<template>
  <Dialog  v-model='visible' :options='dialogOptions.dialog'>
    <v-form
      v-if='Object.keys(info).length'
      ref='form'
      style='display: flex; align-items: center; position: relative'
    >
      <v-row style='flex: 1; margin: 0'>
        <template v-for='e of panel'>
          <v-col v-if='e.visible ? e.visible(info) : true' :key='e.field' cols='12'>
            <span>{{ e.text }}：</span>
            <span :style='e.style ? e.style(info) : {}' class='breakAll'>
              {{
                e.format
                  ? e.format(info[e.field])
                  : info[e.field]
                    ? e.field === 'reviewStatusText' ? info[e.field] +'-'+ info.handleFlagText : info[e.field]
                    : '-'
              }}
            </span>
          </v-col>
        </template>
      </v-row>
      <div
        style='
          width: 960px;
          height: 540px;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
        '
      >
        <img
          v-if='
            !info.attachList ||
            !info.attachList[0] ||
            isImage(info.attachList[0])
          '
          v-lazy='`${$imgUrl}${info.attachList && info.attachList[0]}`'
          :style="`height: 100%;flex: 1; object-fit: contain; overflow: hidden;cursor:${
            isImage(info.attachList && info.attachList[0]) ? 'pointer' : 'auto'
          }`"
          alt='违规'
          @click="
            info.attachList && info.attachList[0]
              ? $bus.$emit('ImgDialog', {
                  imgUrl: `${$imgUrl}${info.attachList[0]}`,
                })
              : null
          "
        />
        <video
          v-else
          :src='`${$imgUrl}${info.attachList[0]}`'
          controls
          style='flex: 1; object-fit: contain; overflow: hidden;height:100%'
        ></video>
      </div>
    </v-form>
  </Dialog>
</template>

<script>
import { mapState } from 'vuex';
import { assiginDeep, isImage } from '@/views/screen/config';
import Dialog from '@/views/screen/components/Dialog.vue';
export default {
  //组件注册
  components: {
    Dialog
  },
  //组件传值
  props: {
    prefix: {
      type: String,
      default: 'home',
    },
  },
  data() {
    return {
      isIssue: false,
      isErr: false,
      isMessage: false,
      message: undefined,
      form: {
        remark: undefined,
      },
      overlay: false,
      panel: [
        {
          text: '机构',
          field: 'organizationText',
        },
        {
          text: '区域',
          field: 'areaText',
        },
        {
          text: '检测类型',
          field: 'analysisItemText',
        },
        {
          text: '操作人',
          field: 'operatorName',
        },
        {
          text: '结果',
          field: 'violationFlagText',
          style: (info) => ({
            color: info.violationFlag == '02' ? '#fe1329' : '#2cb7ff',
          }),
        },
        {
          text: '摄像头名称',
          field: 'cameraName',
        },
        {
          text: '大门开关',
          field: 'description',
        },
        {
          text: '时间',
          field: 'warningTime',
        },
      ],
      info: {},
      visible: false,
      dialogOptions: {
        dialog: {
          width: 1320,
          title: '开关门',
        },
      },
    };
  },
  //计算属性
  computed: {
    ...mapState({
      pageKey: (state) => state.screenStore.pageKey,
      isSubbranch: (state) => state.screenStore.isSubbranch,
    }),
  },
  //属性监听
  watch: {
    visible() {
      if (this.visible) {
        this.info = {};
        this.form = {
          remark: undefined,
        };
        this.overlay = false;
        this.isIssue = false;
        this.isMessage = false;
        this.isErr = false;
        this.dialogOptions = {
          dialog: {
            width: 1320,
            title: '开关门',
          },
        };
      }
    },
  },
  //DOM访问
  mounted() {
  },
  //保存方法
  methods: {
    isImage,
    openDialog(options = {}) {
      this.visible = true;
      console.log(options.row.attachList,isImage(options.row.attachList[0]));
      this.dialogOptions = assiginDeep(this.dialogOptions, { ...options });
      // this.setPanel(options.type, options.row.analysisItemText, options.row.handleFlag);
      this.$nextTick(() => {
        if (options.row) {
          this.form.uuid = options.row.uuid;
          this.info = options.row;
          this.dialogOptions.dialog.title += options.row.description ? '-' + options.row.description : '';

        }
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.btn-box {
  width: 90px;
  height: 40px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #25e3ff;
  border: 1px solid #2cadff;
  box-shadow: #387992 0px -1px 20px 0px inset;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:not(&:first-child) {
    margin-left: 8px;
  }
}

.col {
  font-size: 16px;
  font-weight: 400;
  color: #d2e2e7;
}

.breakAll {
  word-break: break-all;
}
</style>
