var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{attrs:{"options":_vm.dialogOptions.dialog},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[(Object.keys(_vm.info).length)?_c('v-form',{ref:"form",staticStyle:{"display":"flex","align-items":"center","position":"relative"}},[_c('v-row',{staticStyle:{"flex":"1","margin":"0"}},[_vm._l((_vm.panel),function(e){return [(e.visible ? e.visible(_vm.info) : true)?_c('v-col',{key:e.field,attrs:{"cols":"12"}},[_c('span',[_vm._v(_vm._s(e.text)+"：")]),_c('span',{staticClass:"breakAll",style:(e.style ? e.style(_vm.info) : {})},[_vm._v(" "+_vm._s(e.format ? e.format(_vm.info[e.field]) : _vm.info[e.field] ? e.field === 'reviewStatusText' ? _vm.info[e.field] +'-'+ _vm.info.handleFlagText : _vm.info[e.field] : '-')+" ")])]):_vm._e()]})],2),_c('div',{staticStyle:{"width":"960px","height":"540px","display":"flex","align-items":"center","justify-content":"center","overflow":"hidden"}},[(
          !_vm.info.attachList ||
          !_vm.info.attachList[0] ||
          _vm.isImage(_vm.info.attachList[0])
        )?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(("" + _vm.$imgUrl + (_vm.info.attachList && _vm.info.attachList[0]))),expression:"`${$imgUrl}${info.attachList && info.attachList[0]}`"}],style:(("height: 100%;flex: 1; object-fit: contain; overflow: hidden;cursor:" + (_vm.isImage(_vm.info.attachList && _vm.info.attachList[0]) ? 'pointer' : 'auto'))),attrs:{"alt":"违规"},on:{"click":function($event){_vm.info.attachList && _vm.info.attachList[0]
            ? _vm.$bus.$emit('ImgDialog', {
                imgUrl: ("" + _vm.$imgUrl + (_vm.info.attachList[0])),
              })
            : null}}}):_c('video',{staticStyle:{"flex":"1","object-fit":"contain","overflow":"hidden","height":"100%"},attrs:{"src":("" + _vm.$imgUrl + (_vm.info.attachList[0])),"controls":""}})])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }